import { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';

interface Device {
  id: string;
  device_id: string;
  hardware_info: any;
  status: string;
  license_type: string;
  created_at: string;
  trial_end: string;
}

export function Devices() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadDevices() {
      try {
        const { data, error } = await supabase
          .from('devices')
          .select('*')
          .order('created_at', { ascending: false });

        if (error) throw error;
        setDevices(data || []);
      } catch (error) {
        console.error('Erro ao carregar dispositivos:', error);
      } finally {
        setLoading(false);
      }
    }

    loadDevices();
  }, []);

  if (loading) {
    return <div className="flex items-center justify-center h-full">Carregando...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Dispositivos</h1>
      
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        {devices.map((device) => (
          <div 
            key={device.id} 
            className="bg-zinc-800 p-4 rounded-lg"
          >
            <div className="flex justify-between items-start mb-4">
              <h2 className="font-semibold">{device.device_id}</h2>
              <span className={`px-2 py-1 rounded text-sm ${
                device.status === 'active' ? 'bg-green-500/20 text-green-400' : 
                'bg-red-500/20 text-red-400'
              }`}>
                {device.status}
              </span>
            </div>
            
            <div className="space-y-2 text-sm text-zinc-400">
              <p>Licença: {device.license_type}</p>
              <p>Criado em: {new Date(device.created_at).toLocaleDateString('pt-BR')}</p>
              <p>Trial até: {new Date(device.trial_end).toLocaleDateString('pt-BR')}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 