import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Activity,
  Clock,
  AlertTriangle,
  Check,
  Search,
  Filter
} from 'lucide-react';
import { supabase, subscribeToDevices } from '../lib/supabase';

interface Device {
  id: string;
  device_id: string;
  status: 'active' | 'blocked';
  license_type: 'TRIAL' | 'PAID' | 'EXPIRED';
  created_at: string;
  trial_end: string;
  license_expiration: string | null;
  last_check: string | null;
  memory_usage: number | null;
  gpu_usage: number | null;
  hardware_info: string;
}

export default function Dashboard() {
  const [devices, setDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<'all' | 'active' | 'blocked'>('all');
  const [licenseFilter, setLicenseFilter] = useState<'all' | 'TRIAL' | 'PAID' | 'EXPIRED'>('all');
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
    loadDevices();

    // Inscreve-se para atualizações em tempo real
    const unsubscribe = subscribeToDevices((updatedDevices) => {
      setDevices(updatedDevices);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const checkAuth = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session || session.user.email !== 'admin@tteslasystem.com') {
      navigate('/login');
    }
  };

  const loadDevices = async () => {
    try {
      const { data, error } = await supabase
        .from('devices')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setDevices(data || []);
    } catch (error) {
      console.error('Erro ao carregar dispositivos:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateLicense = async (deviceId: string, licenseType: 'TRIAL' | 'PAID' | 'EXPIRED') => {
    try {
      const { error } = await supabase
        .from('devices')
        .update({ 
          license_type: licenseType,
          license_expiration: licenseType === 'PAID' 
            ? new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString() 
            : null
        })
        .eq('device_id', deviceId);

      if (error) throw error;
    } catch (error) {
      console.error('Erro ao atualizar licença:', error);
    }
  };

  const handleUpdateStatus = async (deviceId: string, status: 'active' | 'blocked') => {
    try {
      const { error } = await supabase
        .from('devices')
        .update({ status })
        .eq('device_id', deviceId);

      if (error) throw error;

      // Registra o bloqueio se o status for 'blocked'
      if (status === 'blocked') {
        await supabase
          .from('device_blocks')
          .insert({
            device_id: deviceId,
            reason: 'manual_block',
            notes: 'Bloqueio manual pelo administrador',
            blocked_by: 'admin@tteslasystem.com'
          });
      }
    } catch (error) {
      console.error('Erro ao atualizar status:', error);
    }
  };

  // Filtra dispositivos baseado na busca e filtros
  const filteredDevices = devices.filter(device => {
    const matchesSearch = device.device_id.toLowerCase().includes(search.toLowerCase());
    const matchesStatus = filter === 'all' || device.status === filter;
    const matchesLicense = licenseFilter === 'all' || device.license_type === licenseFilter;
    return matchesSearch && matchesStatus && matchesLicense;
  });

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white">Dashboard</h1>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-300">Dispositivos Ativos</h3>
            <Activity className="h-5 w-5 text-green-500" />
          </div>
          <p className="text-3xl font-bold text-white">
            {devices.filter(d => d.status === 'active').length}
          </p>
        </div>
        
        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-300">Licenças Pagas</h3>
            <Check className="h-5 w-5 text-blue-500" />
          </div>
          <p className="text-3xl font-bold text-white">
            {devices.filter(d => d.license_type === 'PAID').length}
          </p>
        </div>

        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-300">Em Trial</h3>
            <Clock className="h-5 w-5 text-yellow-500" />
          </div>
          <p className="text-3xl font-bold text-white">
            {devices.filter(d => d.license_type === 'TRIAL').length}
          </p>
        </div>

        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-300">Bloqueados</h3>
            <AlertTriangle className="h-5 w-5 text-red-500" />
          </div>
          <p className="text-3xl font-bold text-white">
            {devices.filter(d => d.status === 'blocked').length}
          </p>
        </div>
      </div>

      {/* Filtros e Busca */}
      <div className="bg-gray-800 rounded-xl p-6 mb-8">
        <div className="flex flex-wrap gap-4">
          <div className="flex-1">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Buscar por ID do dispositivo..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg pl-10 pr-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
          
          <div className="flex gap-4">
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value as 'all' | 'active' | 'blocked')}
              className="bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="all">Todos os Status</option>
              <option value="active">Ativos</option>
              <option value="blocked">Bloqueados</option>
            </select>

            <select
              value={licenseFilter}
              onChange={(e) => setLicenseFilter(e.target.value as 'all' | 'TRIAL' | 'PAID' | 'EXPIRED')}
              className="bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="all">Todas as Licenças</option>
              <option value="TRIAL">Trial</option>
              <option value="PAID">Pagas</option>
              <option value="EXPIRED">Expiradas</option>
            </select>
          </div>
        </div>
      </div>

      {/* Devices Table */}
      <div className="bg-gray-800 rounded-xl p-6">
        <h3 className="text-lg font-medium text-white mb-4">Dispositivos</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="text-gray-400 text-left">
                <th className="pb-4">ID do Dispositivo</th>
                <th className="pb-4">Status</th>
                <th className="pb-4">Tipo de Licença</th>
                <th className="pb-4">Criado em</th>
                <th className="pb-4">Expira em</th>
                <th className="pb-4">Último Check</th>
                <th className="pb-4">Memória</th>
                <th className="pb-4">Ações</th>
              </tr>
            </thead>
            <tbody className="text-gray-300">
              {filteredDevices.map(device => (
                <tr key={device.id} className="border-t border-gray-700">
                  <td className="py-4">
                    <div className="flex flex-col">
                      <span>{device.device_id.slice(0, 8)}...</span>
                      <span className="text-xs text-gray-500">
                        {JSON.parse(device.hardware_info).model || 'N/A'}
                      </span>
                    </div>
                  </td>
                  <td className="py-4">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      device.status === 'active' 
                        ? 'bg-green-500/20 text-green-300'
                        : 'bg-red-500/20 text-red-300'
                    }`}>
                      {device.status === 'active' ? 'Ativo' : 'Bloqueado'}
                    </span>
                  </td>
                  <td className="py-4">
                    <span className={`px-2 py-1 rounded-full text-xs ${
                      device.license_type === 'PAID'
                        ? 'bg-blue-500/20 text-blue-300'
                        : device.license_type === 'TRIAL'
                        ? 'bg-yellow-500/20 text-yellow-300'
                        : 'bg-gray-500/20 text-gray-300'
                    }`}>
                      {device.license_type}
                    </span>
                  </td>
                  <td className="py-4">
                    {new Date(device.created_at).toLocaleDateString()}
                  </td>
                  <td className="py-4">
                    {device.license_type === 'PAID' && device.license_expiration
                      ? new Date(device.license_expiration).toLocaleDateString()
                      : device.license_type === 'TRIAL'
                      ? new Date(device.trial_end).toLocaleDateString()
                      : '-'}
                  </td>
                  <td className="py-4">
                    {device.last_check 
                      ? new Date(device.last_check).toLocaleString()
                      : 'Nunca'}
                  </td>
                  <td className="py-4">
                    {device.memory_usage 
                      ? `${device.memory_usage}%`
                      : '-'}
                  </td>
                  <td className="py-4">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleUpdateLicense(device.device_id, 'PAID')}
                        className="px-3 py-1 bg-blue-600 text-white rounded-lg text-sm hover:bg-blue-700"
                      >
                        Ativar Licença
                      </button>
                      <button
                        onClick={() => handleUpdateStatus(
                          device.device_id,
                          device.status === 'active' ? 'blocked' : 'active'
                        )}
                        className={`px-3 py-1 rounded-lg text-sm ${
                          device.status === 'active'
                            ? 'bg-red-600 hover:bg-red-700 text-white'
                            : 'bg-green-600 hover:bg-green-700 text-white'
                        }`}
                      >
                        {device.status === 'active' ? 'Bloquear' : 'Desbloquear'}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}