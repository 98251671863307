import React, { useState } from 'react';
import { supabase } from '../lib/supabase';
import { 
  Shield, 
  Clock, 
  AlertTriangle,
  Save
} from 'lucide-react';

interface SystemSettings {
  trialDays: number;
  maxLoginAttempts: number;
  blockDuration: number;
  notifyLowMemory: boolean;
  memoryThreshold: number;
}

export default function Settings() {
  const [settings, setSettings] = useState<SystemSettings>({
    trialDays: 7,
    maxLoginAttempts: 3,
    blockDuration: 24,
    notifyLowMemory: true,
    memoryThreshold: 80
  });
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const handleSave = async () => {
    setSaving(true);
    setMessage({ type: '', text: '' });

    try {
      const { error } = await supabase
        .from('system_settings')
        .upsert({ 
          id: 1, // Único registro de configurações
          ...settings,
          updated_at: new Date().toISOString()
        });

      if (error) throw error;
      
      setMessage({ 
        type: 'success', 
        text: 'Configurações salvas com sucesso!' 
      });
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: 'Erro ao salvar configurações.' 
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-white">Configurações do Sistema</h1>
      </div>

      <div className="grid grid-cols-1 gap-8">
        {/* Trial e Licença */}
        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center gap-2 mb-6">
            <Clock className="h-6 w-6 text-blue-500" />
            <h2 className="text-xl font-semibold text-white">Trial e Licença</h2>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Duração do Trial (dias)
              </label>
              <input
                type="number"
                value={settings.trialDays}
                onChange={(e) => setSettings({
                  ...settings,
                  trialDays: parseInt(e.target.value)
                })}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>

        {/* Segurança */}
        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center gap-2 mb-6">
            <Shield className="h-6 w-6 text-blue-500" />
            <h2 className="text-xl font-semibold text-white">Segurança</h2>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Máximo de Tentativas de Login
              </label>
              <input
                type="number"
                value={settings.maxLoginAttempts}
                onChange={(e) => setSettings({
                  ...settings,
                  maxLoginAttempts: parseInt(e.target.value)
                })}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Duração do Bloqueio (horas)
              </label>
              <input
                type="number"
                value={settings.blockDuration}
                onChange={(e) => setSettings({
                  ...settings,
                  blockDuration: parseInt(e.target.value)
                })}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>

        {/* Monitoramento */}
        <div className="bg-gray-800 rounded-xl p-6">
          <div className="flex items-center gap-2 mb-6">
            <AlertTriangle className="h-6 w-6 text-blue-500" />
            <h2 className="text-xl font-semibold text-white">Monitoramento</h2>
          </div>
          
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="notifyLowMemory"
                checked={settings.notifyLowMemory}
                onChange={(e) => setSettings({
                  ...settings,
                  notifyLowMemory: e.target.checked
                })}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-600 rounded"
              />
              <label htmlFor="notifyLowMemory" className="ml-2 text-sm font-medium text-gray-300">
                Notificar Uso Alto de Memória
              </label>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Limite de Memória (%)
              </label>
              <input
                type="number"
                value={settings.memoryThreshold}
                onChange={(e) => setSettings({
                  ...settings,
                  memoryThreshold: parseInt(e.target.value)
                })}
                className="w-full bg-gray-700 border border-gray-600 rounded-lg px-4 py-2 text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Mensagem de feedback */}
      {message.text && (
        <div className={`mt-4 p-4 rounded-lg ${
          message.type === 'success' 
            ? 'bg-green-900/50 border border-green-500 text-green-200'
            : 'bg-red-900/50 border border-red-500 text-red-200'
        }`}>
          {message.text}
        </div>
      )}

      {/* Botão Salvar */}
      <div className="mt-8">
        <button
          onClick={handleSave}
          disabled={saving}
          className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg font-semibold disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Save className="h-5 w-5" />
          {saving ? 'Salvando...' : 'Salvar Configurações'}
        </button>
      </div>
    </div>
  );
}