import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Car, 
  Navigation, 
  Zap, 
  ChevronRight, 
  LayoutDashboard,
  Clock,
  Shield
} from 'lucide-react';

const features = [
  {
    icon: <Car className="h-6 w-6" />,
    title: "Integração Tesla",
    description: "Use o display do seu Tesla para navegação do Uber de forma integrada e segura"
  },
  {
    icon: <Navigation className="h-6 w-6" />,
    title: "Navegação Nativa",
    description: "Receba as rotas diretamente no sistema de navegação do Tesla"
  },
  {
    icon: <Zap className="h-6 w-6" />,
    title: "Performance Otimizada",
    description: "Sistema leve e rápido, integrado perfeitamente com seu Tesla"
  }
];

export default function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800">
      {/* Header */}
      <header className="container mx-auto px-6 py-8">
        <nav className="flex items-center justify-between">
          <div className="text-white text-2xl font-bold">T Tesla System</div>
          <div className="space-x-8">
            <Link to="/login" className="text-gray-300 hover:text-white transition-colors">
              Login
            </Link>
            <a 
              href="#contact" 
              className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg transition-colors"
            >
              Contato
            </a>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <main className="container mx-auto px-6 py-20 text-center">
        <h1 className="text-5xl font-bold text-white mb-6">
          Transforme seu Tesla no melhor parceiro para Uber
        </h1>
        <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
          Use o display do seu Tesla para navegar com o Uber. Sistema exclusivo de integração que torna sua experiência mais segura e profissional.
        </p>
        <div className="flex justify-center gap-6">
          <a 
            href="#contact"
            className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-lg font-semibold flex items-center gap-2 transition-colors"
          >
            Começar Agora <ChevronRight className="h-5 w-5" />
          </a>
          <a 
            href="#features"
            className="border border-gray-600 text-gray-300 hover:text-white px-8 py-3 rounded-lg text-lg font-semibold transition-colors"
          >
            Como Funciona
          </a>
        </div>
      </main>

      {/* Features Section */}
      <section id="features" className="bg-gray-800 py-20">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-white text-center mb-12">
            Recursos Exclusivos
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div 
                key={index}
                className="bg-gray-700 p-6 rounded-xl hover:bg-gray-600 transition-colors"
              >
                <div className="text-blue-500 mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-white mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-300">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-20 bg-gray-900">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            <div>
              <LayoutDashboard className="h-8 w-8 text-blue-500 mx-auto mb-4" />
              <div className="text-4xl font-bold text-white mb-2">100%</div>
              <div className="text-gray-400">Integração Tesla</div>
            </div>
            <div>
              <Clock className="h-8 w-8 text-blue-500 mx-auto mb-4" />
              <div className="text-4xl font-bold text-white mb-2">24/7</div>
              <div className="text-gray-400">Suporte</div>
            </div>
            <div>
              <Shield className="h-8 w-8 text-blue-500 mx-auto mb-4" />
              <div className="text-4xl font-bold text-white mb-2">Seguro</div>
              <div className="text-gray-400">e Confiável</div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="bg-gray-800 py-20">
        <div className="container mx-auto px-6 max-w-md text-center">
          <h2 className="text-3xl font-bold text-white mb-8">Entre em Contato</h2>
          <p className="text-gray-300 mb-8">
            Interessado em transformar seu Tesla em uma ferramenta profissional para Uber? Entre em contato conosco!
          </p>
          <a 
            href="mailto:contato@tteslasystem.com"
            className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-lg text-lg font-semibold inline-block transition-colors"
          >
            contato@tteslasystem.com
          </a>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 border-t border-gray-800 py-12">
        <div className="container mx-auto px-6 text-center text-gray-400">
          <p>&copy; 2024 T Tesla System. Todos os direitos reservados.</p>
        </div>
      </footer>
    </div>
  );
}