import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Variáveis de ambiente do Supabase não encontradas:', {
    url: supabaseUrl,
    key: supabaseAnonKey ? '[PRESENTE]' : '[AUSENTE]'
  });
  throw new Error('Faltam variáveis de ambiente do Supabase');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
});

// Autenticação
export async function signInAsAdmin(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });

  if (error) throw error;
  if (data.user?.email !== 'admin@tteslasystem.com') {
    throw new Error('Acesso não autorizado');
  }

  return data;
}

export async function signOut() {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
}

// Realtime subscription para dispositivos
export function subscribeToDevices(callback: (devices: any[]) => void) {
  const subscription = supabase
    .channel('devices-changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
        table: 'devices'
      },
      async () => {
        // Recarrega todos os dispositivos quando houver mudança
        const { data } = await supabase
          .from('devices')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (data) callback(data);
      }
    )
    .subscribe();

  return () => {
    subscription.unsubscribe();
  };
}