import { create } from 'zustand';
import type { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';

interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  setUser: (user: User | null) => void;
  checkAuth: () => Promise<void>;
}

type SetState = (
  partial: AuthState | Partial<AuthState> | ((state: AuthState) => AuthState | Partial<AuthState>),
  replace?: boolean | undefined
) => void;

export const useAuth = create<AuthState>((set: SetState) => ({
  isAuthenticated: false,
  user: null,
  setUser: (user: User | null) => set({ user, isAuthenticated: !!user }),
  checkAuth: async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      set({ 
        user: session?.user || null,
        isAuthenticated: !!session?.user
      });
    } catch (error) {
      console.error('Erro ao verificar autenticação:', error);
      set({ user: null, isAuthenticated: false });
    }
  }
})); 