import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Home, 
  Settings, 
  Smartphone,
  LogOut
} from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../hooks/useAuth';

export function Sidebar() {
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  return (
    <aside className="space-y-6 border-r border-zinc-800 px-5 py-8">
      <nav className="space-y-6">
        <Link 
          to="/dashboard"
          className="flex items-center gap-3 text-zinc-300 hover:text-zinc-100"
        >
          <Home className="h-5 w-5" />
          Dashboard
        </Link>

        <Link 
          to="/devices"
          className="flex items-center gap-3 text-zinc-300 hover:text-zinc-100"
        >
          <Smartphone className="h-5 w-5" />
          Dispositivos
        </Link>

        <Link 
          to="/settings"
          className="flex items-center gap-3 text-zinc-300 hover:text-zinc-100"
        >
          <Settings className="h-5 w-5" />
          Configurações
        </Link>

        <button 
          onClick={handleLogout}
          className="flex items-center gap-3 text-zinc-300 hover:text-zinc-100"
        >
          <LogOut className="h-5 w-5" />
          Sair
        </button>
      </nav>
    </aside>
  );
}